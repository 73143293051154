import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import $ from "jquery";
import Swal from "sweetalert2";
import Select from 'react-select'
import commaNumber from "comma-number";

const Slides = ({ icon }) => {

    const [data, setData] = useState([]);
    const [product, setProduct] = useState([]);
    const getData = async () => {
        const { data } = await axios.get('/slide');
        setData(data);
        const { data: product } = await axios.get('/product');
        setProduct(product.map((item) => {
            return {
                value: item.product_id,
                label: item.product_name,
            };
        }));
    };

    useEffect(() => {
        if (data.length > 0) {
            if (!window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                window.$('.dt-tbl').DataTable();
            }
        }
    }, [data]);

    useEffect(() => {
        getData();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">Main</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Slides</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">Slides</h6>
                                </div>
                                <div className="col-md-6 d-flex justify-content-end">
                                    <button className="btn btn-sm btn-dark" data-bs-toggle="modal" data-bs-target="#new">
                                        <icon.Plus />
                                        <span className="ms-2">New Slide</span>
                                    </button>
                                    <div className="modal fade" id="new" tabIndex={-1} aria-labelledby="new" aria-hidden="true">
                                        <div className="modal-dialog modal-lg">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel">New Slide</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                </div>
                                                <form onSubmit={(e) => {
                                                    e.preventDefault();
                                                    const formData = new FormData(e.target);
                                                    const data = Object.fromEntries(formData);
                                                    axios.post(`/slide`, data).then(() => {
                                                        window.$(`#new`).modal('hide');
                                                        toast.success('Added successfully');
                                                        getData();
                                                    })
                                                }} >
                                                    <div className="modal-body">
                                                        <div className="mb-3">
                                                            <label className="form-label">Select Product For Slide</label>
                                                            <Select options={product} name="product_id" placeholder="Select Product" required />
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">close</button>
                                                        <button type="submit" className="btn btn-primary">Submit</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <table className="table table-striped table-sm dt-tbl" style={{ width: "100%" }}>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Product</th>
                                        <th>Price</th>
                                        <th>Discount</th>
                                        <th>Discount Price</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, index) => (
                                        <tr key={index}>
                                            <td><img src={item.thumbnail} alt={item.product_name} className="img-fluid" /></td>
                                            <td>{item.product_name}</td>
                                            <td>${commaNumber(item.price)}</td>
                                            <td>${commaNumber(item.discount)}</td>
                                            <td>${commaNumber(item.discounted_price)}</td>
                                            <td>
                                                <span type="button" className="badge bg-danger mx-1"
                                                    onClick={() => {
                                                        Swal.fire({
                                                            title: 'Are you sure?',
                                                            text: "You won't be able to revert this!",
                                                            icon: 'warning',
                                                            showCancelButton: true,
                                                            confirmButtonColor: '#3085d6',
                                                            cancelButtonColor: '#d33',
                                                            confirmButtonText: "Yes, delete it!",
                                                        }).then((result) => {
                                                            if (result.isConfirmed) {
                                                                axios.delete(`/slide/${item.id}`).then(() => {
                                                                    getData();
                                                                })
                                                            }
                                                        })
                                                    }}>
                                                    <icon.Trash size="18" />
                                                </span>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Slides;