import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import $ from "jquery";
import Select from 'react-select'
import commaNumber from "comma-number";
import Pagination from "../Components/Pagination";

const Products = ({ icon }) => {

    const [category, setCategory] = useState([]);
    const [brand, setBrand] = useState([]);
    const getData = async () => {
        const { data: category } = await axios.get('/category');
        setCategory(category.map((item) => {
            return {
                value: item.id,
                label: item.category_name,
            };
        }));
        const { data: brand } = await axios.get('/brand');
        setBrand(brand.map((item) => {
            return {
                value: item.id,
                label: item.brand_name,
            };
        }));

    };

    useEffect(() => {
        getData();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);


    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [search, setSearch] = useState('');
    const fetchData = async (page) => {
        const { data } = await axios.get('/product-list', { params: { page: page, search: search } });
        setData(data.data);
        setTotalPages(Math.ceil(data.totalPages / 10));
    }

    useEffect(() => {
        fetchData(currentPage);
    }, [currentPage]);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const [selectedFile, setSelectedFile] = useState(null);
    const uploadChange = async (e) => {
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        const { data } = await axios.post("/upload", formData);
        setSelectedFile(data);
    };


    const [selectedFiles, setSelectedFiles] = useState([]);

    const uploadImage = async (e) => {
        const files = Array.from(e.target.files);
        const formData = new FormData();
        files.forEach((file, index) => {
            formData.append("files[" + index + "]", file);
        });
        try {
            const { data } = await axios.post("/upload-images", formData);
            setSelectedFiles(data);
            console.log(data)
        } catch (error) {
            console.error("Error uploading files:", error);
        }
    };

    const [currentCategory, setCurrentCategory] = useState([])
    const [currentBrand, setCurrentBrand] = useState([])
    const [currentProductid, setCurrentProductid] = useState(null)

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">Main</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Products</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">Product List</h6>
                                </div>
                                <div className="col-md-6 d-flex justify-content-end">
                                    <button className="btn btn-sm btn-dark" data-bs-toggle="modal" data-bs-target="#new">
                                        <icon.Plus />
                                        <span className="ms-2">New Product</span>
                                    </button>
                                    <div className="modal fade" id="new" tabIndex={-1} aria-labelledby="new" aria-hidden="true">
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="newproduct">New Product</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                </div>
                                                <form onSubmit={(e) => {
                                                    e.preventDefault();
                                                    const formData = new FormData(e.target);
                                                    const data = Object.fromEntries(formData);
                                                    axios.post(`/product`, data).then((res) => {
                                                        setCurrentProductid(res.data)
                                                        getData();
                                                        window.$(`#new`).modal('hide');
                                                        window.$(`#gallery`).modal('show');
                                                    })
                                                }} >
                                                    <div className="modal-body">
                                                        <div className="mb-3">
                                                            <label className="form-label">Category</label>
                                                            <Select options={category} name="category_id" placeholder="Select Category" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Brand</label>
                                                            <Select options={brand} name="brand_id" placeholder="Select Brand" />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Product Name (English)</label>
                                                            <input type="text" className="form-control" name="product_name" placeholder="Product Name" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Description (English)</label>
                                                            <textarea type="text" className="form-control" name="description" placeholder="Description Name" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Price</label>
                                                            <input type="number" step="any" className="form-control" name="price" placeholder="0.00" defaultValue={0.00} />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Discount</label>
                                                            <input type="number" step="any" className="form-control" name="discount" placeholder="0.00" defaultValue={0.00} />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Image</label>
                                                            <input type="file" className="form-control" onChange={uploadChange} accept="application/pdf, image/*" required />
                                                            <input type="hidden" name="thumbnail" defaultValue={selectedFile} required />
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                        <button type="submit" className="btn btn-primary">Next</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal fade" id="gallery" tabIndex={-1} aria-labelledby="new" aria-hidden="true">
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="newproduct">Add Product Images</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                </div>
                                                <form onSubmit={(e) => {
                                                    e.preventDefault();
                                                    axios.post(`/product-image`, { images: selectedFiles, product_id: currentProductid }).then(() => {
                                                        window.$(`#gallery`).modal('hide');
                                                        toast.success('Added Successfully');
                                                        getData();
                                                    }).catch(err => {
                                                        console.error("Error submitting form:", err);
                                                    });
                                                }}>
                                                    <div className="modal-body">
                                                        <div className="mb-3">
                                                            <label className="form-label">Image (Optional)</label>
                                                            <input type="file" className="form-control" onChange={uploadImage} accept="application/pdf, image/*" multiple />
                                                            <div className="row mt-3">
                                                                {selectedFiles.map((file, index) => (
                                                                    <div className="col-md-3" key={index}>
                                                                        <img className="img-fluid rounded" src={file} alt={`uploaded-img-${index}`} />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                        <button type="submit" className="btn btn-primary">Submit</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control" placeholder="Search" onChange={(e) => { setSearch(e.target.value); setCurrentPage(1); fetchData(1); }} />
                                </div>
                                <table className="table table-striped table-sm">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th></th>
                                            <th>Product</th>
                                            <th>Category</th>
                                            <th>Brand</th>
                                            <th>Price</th>
                                            <th>Discount</th>
                                            <th>Discount Price</th>
                                            <th>Views</th>
                                            <th>Created At</th>
                                            <th>option</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((item, index) => {
                                            return (
                                                <tr key={item.product_id}>
                                                    <td>{index + 1}</td>
                                                    <td><img src={item.thumbnail} /></td>
                                                    <td>{item.product_name}</td>
                                                    <td>{item.category_name}</td>
                                                    <td>{item.brand_name}</td>
                                                    <td>${commaNumber(item.price)}</td>
                                                    <td>%{item.discount}</td>
                                                    <td>${commaNumber(item.discounted_price)}</td>
                                                    <td>{commaNumber(item.views)}</td>
                                                    <td>{new Date(item.created).toLocaleDateString()}</td>
                                                    <td>
                                                        <span type="button" className="badge bg-warning text-dark  mx-1" data-bs-toggle="modal" data-bs-target={`#edit${item.product_id}`} onClick={() => {
                                                            setCurrentCategory({ value: item.category_id, label: item.category_name })
                                                            setCurrentBrand({ value: item.brand_id, label: item.brand_name })
                                                            setCurrentProductid(item.product_id)
                                                        }}>
                                                            <icon.Edit size="18" />
                                                        </span>
                                                        <span type="button" className="badge bg-danger mx-1"
                                                            onClick={() => {
                                                                Swal.fire({
                                                                    title: 'Are you sure?',
                                                                    text: "You won't be able to revert this!",
                                                                    icon: 'warning',
                                                                    showCancelButton: true,
                                                                    confirmButtonColor: '#3085d6',
                                                                    cancelButtonColor: '#d33',
                                                                    confirmButtonText: "Yes, delete it!",
                                                                }).then((result) => {
                                                                    if (result.isConfirmed) {
                                                                        axios.delete(`/product/${item.product_id}`).then((res) => {
                                                                            getData();
                                                                        })
                                                                    }
                                                                })
                                                            }}>
                                                            <icon.Trash size="18" />
                                                        </span>
                                                    </td>
                                                    <div className="modal fade" id={`edit${item.product_id}`} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                        <div className="modal-dialog">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title" id="editproduct">Edit {item.product_name}</h5>
                                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                                </div>
                                                                <form onSubmit={(e) => {
                                                                    e.preventDefault();
                                                                    const formData = new FormData(e.target);
                                                                    const data = Object.fromEntries(formData);
                                                                    axios.put(`/product/${item.product_id}`, data).then(() => {
                                                                        getData();
                                                                        window.$(`#edit${item.product_id}`).modal('hide');
                                                                        window.$(`#editgallery`).modal('show');
                                                                    })
                                                                }} >
                                                                    <div className="modal-body">
                                                                        <div className="mb-3">
                                                                            <label className="form-label">Category</label>
                                                                            <Select options={category} name="category_id" placeholder="Select Category" value={currentCategory} onChange={(e) => {
                                                                                setCurrentCategory({ value: e.value, label: e.label });
                                                                            }} required />
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label className="form-label">Brand</label>
                                                                            <Select options={brand} name="brand_id" placeholder="Select Brand" value={currentBrand} onChange={(e) => {
                                                                                setCurrentBrand({ value: e.value, label: e.label });
                                                                            }} />
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label className="form-label">Product Name</label>
                                                                            <input type="text" className="form-control" name="product_name" placeholder="Product Name" defaultValue={item.product_name} required />
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label className="form-label">Description</label>
                                                                            <textarea type="text" className="form-control" name="description" placeholder="Description Name" defaultValue={item.description} required />
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label className="form-label">Price</label>
                                                                            <input type="number" step="any" className="form-control" name="price" placeholder="0.00" defaultValue={item.price} />
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label className="form-label">Discount</label>
                                                                            <input type="number" step="any" className="form-control" name="discount" placeholder="0.00" defaultValue={item.discount} />
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label className="form-label">Image</label>
                                                                            <input type="file" className="form-control" onChange={uploadChange} accept="application/pdf, image/*" />
                                                                            <input type="hidden" name="image" defaultValue={selectedFile ? selectedFile : item.thumbnail} required />
                                                                            <img className="img-fluid rounded mt-2" src={`../../${item.thumbnail}`} alt={item.product_name} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="modal-footer">
                                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                                        <button type="submit" className="btn btn-primary">Next</button>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="modal fade" id="editgallery" tabIndex={-1} aria-labelledby="new" aria-hidden="true">
                                                        <div className="modal-dialog">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title" id="newproduct">Add Product Images</h5>
                                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                                </div>
                                                                <form onSubmit={(e) => {
                                                                    e.preventDefault();
                                                                    axios.post(`/product-image`, { images: selectedFiles, product_id: currentProductid }).then(() => {
                                                                        window.$(`#editgallery`).modal('hide');
                                                                        toast.success('Added Successfully');
                                                                        getData();
                                                                    }).catch(err => {
                                                                        console.error("Error submitting form:", err);
                                                                    });
                                                                }}>
                                                                    <div className="modal-body">
                                                                        <div className="mb-3">
                                                                            <label className="form-label">Image</label>
                                                                            <input type="file" className="form-control" onChange={uploadImage} accept="application/pdf, image/*" multiple />
                                                                            <div className="row mt-3">
                                                                                {selectedFiles.map((file, index) => (
                                                                                    <div className="col-md-3" key={index}>
                                                                                        <img className="img-fluid rounded" src={file} alt={`uploaded-img-${index}`} onClick={() => {
                                                                                            axios.delete(`/product-image/${item.product_id}`).then(() => {
                                                                                            }).catch(error => {
                                                                                                console.error('Error deleting image:', error);
                                                                                            });
                                                                                        }}
                                                                                        />
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="modal-footer">
                                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                                        <button type="submit" className="btn btn-primary">Submit</button>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Products;